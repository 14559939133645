<template>
  <div v-if="showModal" class="custom-modal">
    <div class="modal-content">
      <div class="close" @click="closePopup">X</div>
      <div class="row p-0 h-100 row-width">
        <div class="col p-0 d-flex flex-column justify-content-around align-items-stretch h-100">
          <div class="text-white border-top border-white fw-bold pl-3 pt-2 pb-2 mt-4"
            :class="isSmallScreen ? 'fs-6 border-1' : 'fs-4 border-4'">
            ERGÄNZE UNSER TEAM ALS
          </div>
          <div class="text-primary bg-tertiary fw-bold pl-3 pt-2 pb-2 border-radius"
          :class="isSmallScreen ? 'fs-6' : 'fs-4'">
            ERGOTHERAPEUT:IN<br />
            LOGOPÄD:IN<br />
            PHYSIOTHERAPEUT:IN<br />
            <span class="text-white">W / M / D</span>
          </div>
          <div
            class="fw-bold pl-3 pt-2 pb-2 border-radius job"
            :class="isSmallScreen ? 'fs-6' : 'fs-4'"
            @click="$router.push('/karriere')"
          >
            ERFAHRE MEHR ...
          </div>
          <div
            class="fw-bold pl-3 pt-2 pb-2 mb-4 border-radius job-apply"
            :class="isSmallScreen ? 'fs-6' : 'fs-4'"
            @click="$router.push('/karriere#interest-aroused')"
          >
            JETZT BEWERBEN
          </div>
        </div>
      </div>
      <div class="hide-popup">
        <a href="#" @click="hideModal" class="text-white">nicht mehr anzeigen</a>
      </div>
      <div class="logo">
        <img src="../../assets/img/karriere/logo.png" alt="logo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            isSmallScreen: window.innerWidth <= 500
        };
    },
    methods: {
        updateScreenSize() {
            this.isSmallScreen = window.innerWidth <= 500;
        },
        showPopup() {
            if (!localStorage.getItem('hideWelcomeModal')) {
                this.showModal = true;
            }
        },
        hideModal() {
            this.showModal = false;
            localStorage.setItem('hideWelcomeModal', 'true');
        },
        closePopup() {
            this.showModal = false;
        }
    },
    mounted() {
        this.showPopup();
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenSize);
    }
};
</script>

<style scoped>
.row-width {
  width: 68%;
}

.custom-modal {
  position: fixed;
  top: 30%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal .modal-content {
  position: relative;
  width: 500px;
  height: 500px;
  background-image: url("../../assets/img/karriere/you.jpg");
  background-size: cover;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.custom-modal .modal-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 69, 255, 0.26);
  border-radius: 10px;
}

.custom-modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  opacity: 1;
  background-color: var(--bs-tertiary);
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 2;
}

.custom-modal .logo {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.custom-modal .modal-header {
  border: none;
}

.hide-popup {
  text-align: center;
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.border-radius {
  border-radius: 10px;
}

.job {
  color: var(--bs-white);
  background-color: var(--bs-primary);
}

.job-apply {
  color: var(--bs-tertiary);
  background-color: var(--bs-white);
}

.job:hover {
  color: var(--bs-primary);
  background-color: var(--bs-white);
  cursor: pointer;
  transition: 0.3s;
}

.job-apply:hover {
  color: var(--bs-white);
  background-color: var(--bs-tertiary);
  cursor: pointer;
  transition: 0.3s;
}

@media screen and (max-width: 500px) {
  .custom-modal {
    top: auto;
    bottom: 0;
  }

  .custom-modal .modal-content {
    width: 375px;
    height: 375px;
  }

  .custom-modal .logo {
    display: none;
  }
}
</style>
