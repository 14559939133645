<template>
  <div class="employee-card doctor border-0">
    <div class="employee-card-body p-0 shadow-sm">
      <div class="position-relative">
        <img loading="lazy" class="img-fluid" :src="frontImg" />
      </div>
      <div class="p-4">
        <h6 class="mb-0">{{ name }}</h6>
        <p class="text-muted text-sm mb-0">{{ profession }}</p>
      </div>
      <div class="employee-card-back">
        <div class="position-relative">
          <img loading="lazy" class="img-fluid" :src="backImg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        frontImg: {
            type: String,
            required: true
        },
        backImg: {
            type: String,
            required: false
        },
        profession: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {},
    beforeUnmount() {}
};
</script>

<style>
.employee-card {
  perspective: 1000px;
}

.employee-card:hover .employee-card-body {
  transform: rotateY(180deg);
}

.employee-card-body {
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.employee-card-front,
.employee-card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.employee-card-front {
  z-index: 2;
  transform: rotateY(0deg);
}

.employee-card-back {
  transform: rotateY(180deg);
  background-color: #084765;
  display: flex;
  object-fit: cover;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  z-index: 1;
  /* Den Text auf der Rückseite sichtbar machen */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.border-top-bottom {
  border-top: 5px solid #b3c935;
  border-bottom: 5px solid #b3c935;
}

.colored-block {
  background-color: #08425e;
  padding: 20px;
  border-top: 5px solid #b3c935;
  border-bottom: 5px solid #b3c935;
  position: relative;
  text-align: center;
}

.text-block {
  font-weight: bold;
  color: var(--bs-text);
  background-color: var(--bs-background);
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  border: 2px solid var(--bs-text);
  border-radius: 50px;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.6em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.text-block:hover {
  color: var(--bs-tertiary);
  background-color: #ffffff;
  transition: background-color 0.3s;
  .arrow-block {
    transition: background-color 0.5s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  }
}

.arrow-block {
  background-color: #08425e;
  padding: 20px;
  border-radius: 50px;
  display: inline-block;
  margin: 0 auto;
  font-size: 1.2rem;
  margin-left: 20px;
  cursor: pointer;
}

@media (hover: none) and (pointer: coarse) {
  .employee-card-body:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    clip-path: polygon(0 10%, 100% 0%, 0 100%);
    background: linear-gradient(135deg, #ffffff 10%, #07425e 90%);
    background-color: #08425e;
  }
}

@media (hover: none) and (pointer: coarse) and (max-width: 576px) {
  .employee-card-body:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    clip-path: polygon(0 10%, 100% 0%, 0 100%);
    background: linear-gradient(135deg, #ffffff 10%, #07425e 90%);
    background-color: #08425e;
  }
}
</style>
