<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="header-container text-center">
                    <div ref="header" class="h0 text-primary header">{{ header }}</div>
                    <img ref="lineImage" class="img-fluid" src="../../assets/img/decorations/line_green_horizontal.png" alt="Liniendekoration">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderWithLine',
    props: {
        header: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.setImageWidth();
        window.addEventListener('resize', this.setImageWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setImageWidth);
    },
    methods: {
        setImageWidth() {
            const headerWidth = this.$refs.header.offsetWidth;
            this.$refs.lineImage.style.width = `calc(${headerWidth}px + 10%)`;
        }
    }
};
</script>
<style scoped>

.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.header {
  hyphens: auto;
  z-index: 1;
}
</style>
